export const ADD_EMPLOYEE = "ADD_EMPLOYEE";

export interface Employee {
    user_id: number;
    employee_id:number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    role: string[];
    profilePicture: string;
    status: "ACTIVE" | "INACTIVE" | "SUSPENDED";
  }
