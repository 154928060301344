// reducer.ts
import { SAVE_NAME } from './constants';

// Define the state structure directly here
interface NameState {
  name: string;
}

// Initial state
const initialState: NameState = {
  name: ''
};

// Define action type directly in reducer
type NameActionTypes = { type: typeof SAVE_NAME; payload: string };

// Reducer function
export const nameReducer = (state = initialState, action: NameActionTypes): NameState => {
  switch (action.type) {
    case SAVE_NAME:
      return {
        ...state,
        name: action.payload
      };
    default:
      return state;
  }
};
