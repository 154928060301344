import { lazy, useEffect, useState } from "react";
import "./App.css";
import "./assets/css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/bootstrap.min.css";
//CSS & Bootstrap
import "./assets/css/style.css";
//  import "./assets/js/bootstrap.bundle.min.js";
import "./assets/css/select2.min.css";
import { BrowserRouter } from "react-router-dom";
import { getCookie } from "./utils/cookies/cookies";

const AppRoutes = lazy(() => import("./routes/routes"));

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
 
useEffect(()=>{
  const authentication = getCookie('authenticated');
  let booleanValue = authentication === "true";
  setIsAuthenticated(booleanValue)
},[])
  return (
    <BrowserRouter>
      <AppRoutes
        isAuthenticated={isAuthenticated}
        setIsAuthenticated={setIsAuthenticated}
      />
    </BrowserRouter>
  );
}

export default App;
