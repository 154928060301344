import { ADD_EMPLOYEE, Employee } from "./constant";
import { EmployeeActions } from "./action"; // Import the action type

// Define the state structure
interface EmployeeState {
    employeeList: Employee[];
}

const initialState: EmployeeState = {
  employeeList: [
     {
      user_id: 1,
      employee_id:789,
      firstName: "Andrea",
      lastName: "Lalema",
      email: "andrea@example.com",
      phone: "+1 23 456890",
      role: ["Super-Admin"],
      profilePicture: "blogimg2",
      status: "ACTIVE",
    },
    {
      user_id: 2,
      employee_id:123,
      firstName: "Smith",
      lastName: "Bruklin",
      email: "smith@example.com",
      phone: "+1 23 456891",
      role: ["Partner-Admin"],
      profilePicture: "blogimg4",
      status: "INACTIVE",
    },
    {
      user_id: 3,
      employee_id:111,
      firstName: "William",
      lastName: "Stephin",
      email: "william@example.com",
      phone: "+1 23 456892",
      role: ["Corporate-Admin"],
      profilePicture: "blogimg6",
      status: "SUSPENDED",
    },
    {
        user_id:1111,
        
            employee_id: 444444445221,
            firstName: "sdcds",
            lastName: "nkjnkjnk",
            email: "kjnlknlknlkl@gmail.com",
            phone: "6546464",
            role: [
                "admin",
                "viewer",
                "editor"
            ],
            "profilePicture": "",
            "status": "ACTIVE"
        }
  ],
};

// Use the specific action type in the reducer
export const employeeReducer = (state = initialState, action: EmployeeActions): EmployeeState => {
  switch (action.type) {
    case ADD_EMPLOYEE:
      return {
        ...state,
        employeeList: [...state.employeeList, action.payload],
      };
    default:
      return state;
  }
};
