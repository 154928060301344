import { createStore, applyMiddleware, combineReducers } from 'redux';
import {thunk} from 'redux-thunk';
import { nameReducer } from './reducers';
import  layoutReducer  from '../layout/sidebar/sidebar-redux/reducers'; // Import the new reducer
import { employeeReducer } from '../container/harmoni-panel/redux/reducer';

// Combine the reducers
const rootReducer = combineReducers({
  name: nameReducer, // key for nameReducer
  layout: layoutReducer, // key for the new reducer
  employees:employeeReducer,
});

// Create the store with the combined reducer and middleware
const store = createStore(rootReducer,{}, applyMiddleware(thunk));
export type RootState = ReturnType<typeof rootReducer>;
export default store;
